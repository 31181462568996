<template>
  <b-row>
    <b-col cols="3">
      <b-card title="淘宝订单">
        <h2 class="text-primary">{{ tbcount }}</h2>
      </b-card>
    </b-col>
    <b-col cols="3">
      <b-card title="京东订单">
        <h2 class="text-success">{{ jdcount }}</h2></b-card
      >
    </b-col>
    <b-col cols="3">
      <b-card title="吃喝玩乐订单">
        <h2 class="text-danger">{{ chwlcount }}</h2></b-card
      >
    </b-col>
    <b-col cols="3">
      <b-card title="抖音订单">
        <h2 class="text-warning">{{ dycount }}</h2></b-card
      >
    </b-col>
    <b-col md="3">
      <b-card title="美团订单">
        <h2 class="text-info">{{ mtcount }}</h2></b-card
      >
    </b-col>
    <b-col md="3">
      <b-card title="电影票订单">
        <h2>{{ moivecount }}</h2></b-card
      >
    </b-col>
    <b-col md="3">
      <b-card title="今日总佣金">
        <h2 class="text-success">{{ count }}</h2></b-card
      >
    </b-col>
    <b-col md="3">
      <b-card title="平台佣金">
        <h2 class="text-success">{{ ptcount }}</h2></b-card
      >
    </b-col>
    <b-col md="6">
      <b-card title="订单分析"> 订单分析</b-card>
    </b-col>
    <b-col md="6">
      <b-card title="订单报表"> 订单报表</b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      cid: 0,
      dycount: "",
      jdcount: "",
      tbcount: "",
      chwlcount: "",
      moivecount: "",
      mtcount: "",
      count: "",
      ptcount: "",
    };
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt.querycountorder({ cid: this.cid }).then((res) => {
      console.log(res.data.data);
      this.dycount= res.data.data.dycount
      this.jdcount= res.data.data.jdcount
      this.tbcount= res.data.data.tbcount
      this.chwlcount= res.data.data.chwlcount
      this.moivecount= res.data.data.moivecount
      this.mtcount= res.data.data.mtcount
      this.count= res.data.data.zongyj
      this.ptcount= res.data.data.ptyj
    });
  },
};
</script>
